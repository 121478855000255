import React from 'react'
import Slider from 'components/Slider';
import { languageKey } from 'components/helper';


const SSAboutSecFive = ({
  page,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    rtl: (languageKey === 'ar'),
  };
  return (
    <div>
      <section className="ss_about_sec_five ss_section_sec_bg spacer_top spacer_bottom ss_testimonial"> 
        <div className="container-fluid"> 
          <h3 className="ss_h3_center text-center">{page.label}</h3>
          <h1 className="text-center mb-4">{page.heading}</h1>
          <div>
            <div>
              <Slider settings={settings}>
                {page.slider.map((slide, i) => (
                  <div key={i} className="none-outline clint-slider">
                    <div className="row"> 
                      <div className="col-xl-3 mt-4 offset-xl-3 col-lg-3 offset-lg-2 col-md-4 offset-md-0">	
                        <div className="ss_testimonial_left">
                          <div
                            className="ss_testimonial_img"
                            style={{
                              backgroundImage: `url(${slide.image})`
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className="col-xl-4 col-lg-5 mt-4 col-md-8"
                        style={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >	
                        <div className="ss_testimonial_right">	
                          <p>{slide.description}</p>
                          <h1>{slide.name}</h1>
                          <h2>{slide.job_title}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              </div>
            </div>
        </div>
      </section>
    </div>
  );
}

export default SSAboutSecFive;