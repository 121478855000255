import React from 'react'


const SSAboutMain = ({
  page,
}) => (
  <section className="ss_about_main">
    <div className="container-fluid"> 
      <div className="row justify-content-center">
        <div className="col-lg-12 text-center">
          <div className="ss_ab_right">
            <h3 className="ss_h3_center text-center">{page.label}</h3>
            <h1>{page.heading}</h1>
            <h2>{page.sub_heading}</h2>
            <p>{page.description}</p>
            <h2 className="ss_name">{page.user_name}</h2>
            <p>{page.job_title}</p>
            <img className="img-fluid pt-2" src={page.signature} alt="signature" />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SSAboutMain;
