import React from 'react'
import SSAboutMain from './SSAboutMain'
import SSAboutSecFour from './SSAboutSecFour'
import SSAboutSecFive from './SSAboutSecFive'
import SSAbout from './SSAbout'


const AboutUs = ({
  page,
}) => {
  return (
    <>
      <SSAboutMain page={page} />
      <SSAbout page={page.section_one} />
      <SSAboutSecFour page={page.section_tow} />
      <SSAboutSecFive page={page.section_three} />
    </>
  )
}

export default AboutUs