import React from 'react'
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import Header from 'components/Header';
import SEO from 'components/seo';
import AboutUs from 'components/AboutUs';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';


const AboutUsPage = ({
  data: {markdownRemark: { frontmatter } },
  pageContext,
}) => {

  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={frontmatter.label}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.label}</h1>
                <ul>
                <li><Link to="/">{i18nx('home')}</Link><span> / {frontmatter.label}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <AboutUs page={frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutUS($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "about-us"}}) {
      frontmatter {
        templateType
        title
        wrapper
        video_wrapper
        video_Link
        label
        heading
        sub_heading
        description
        user_name
        job_title
        signature
        section_one {
          label
          heading
          description
          button {
            label
            link
          }
        }
        section_tow {
          label
          heading
          wrapper
          blocks {
            image
            label
            number
          }
        }
        section_three {
          label
          heading
          slider {
            description
            image
            job_title
            name
          }
        }
      }
    }
  }
`

export default AboutUsPage
