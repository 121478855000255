import React from 'react'


const SSAboutSecFour = ({
  page,
}) => (
  <section className="ss_about_sec_four">
    <div className="happy_clients">
      <h3 className="ss_h3_center text-center">{page.label}</h3>
      <h1 className="text-center">{page.heading}</h1>
    </div>
    <div className="ss_about_counter" id="counter">
      <div className="container">
        <div className="row">
          {page.blocks.map((block, i) => (
            <div key={i} className="col-lg-3 col-md-6 mb-2">
              <div className="counter_div text-center">
                <img className="img-fluid" src={block.image} alt="image" />
                <div className="ss_count counter-value" data-count={300}>{block.number}</div>
                <h2>{block.label}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default SSAboutSecFour;
